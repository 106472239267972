import { mapGetters } from "vuex";
import { compact } from "lodash";

export default {
    name: "clients-list",
    methods: {
        compact,
    },
    computed: {
        ...mapGetters({
            user: "profile/user",
        }),
    },
};
